<script setup>
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import { ref } from "vue";
  // import Layout from "../../layouts/main.vue";
  // import PageHeader from "@/components/page-header";
  import i18n from "../../i18n";
  // import store from "@/state/store";
  // import router from "../../router/index";
  import { useRoute } from 'vue-router';
  const route = useRoute();
  import axios from "axios";

  import companyData from '@/data/company.json'

  const { t } = i18n.global;
  const title = t("Belge Görüntüle");

  // const loading = ref(false)

  const items = ref([
    {
      text: "Elektro Portal",
      href: "/",
    },
    {
      text: "Belgeler",
      href: "/belgeler",
    },
    {
      text: t("Belge Görüntüle"),
      active: true,
    },
  ]);

  let product_detail = ref([]);
  let totalTax = ref([])
  let totalDiscount = ref(0);
  let subTotal = ref(0);
  let docTotal = ref(0);
  let docBody = ref([]);
  // let docHead = ref([]);

  if(
      route.params.docType != undefined
      || route.params.docNum != undefined
  ){

    let userData = new FormData();
    userData.append('docNum', route.params.docNum)
    userData.append('docType', route.params.docType)

    axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDocDetail', userData).then((data) => {
      if(data.data.status){
        // product_detail.value = data.data.body.docitems
        // totalTax.value = data.data.body.totalTax
        // totalDiscount.value = data.data.body.totalDiscount
        // subTotal.value = data.data.body.subTotal
        // docTotal.value = data.data.body.docTotal
        docBody.value = data.data.body
        product_detail.value = docBody.value.docitems
        totalTax.value = docBody.value.totalTax
        totalDiscount.value = docBody.value.totalDiscount
        subTotal.value = docBody.value.subTotal
        docTotal.value = docBody.value.docTotal
        items.value.push({
          text : '#'+docBody.value.docnum,
          active: true,
        })

      }
    }).catch((error) => {
      console.log('Bir hata oluştu')
      console.log(error)
    });

  }

</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row class="justify-content-center">
      <b-col xxl="12">
        <b-card no-body id="demo">
          <b-card no-body class="card-success mb-0" style="border-radius: 0.25rem 0.25rem 0 0;" v-if="docBody.typeText == 'Fatura'">
            <b-card-footer>
              <div class="text-center">
                <b-link :href="docBody.eFatura" target="_blank" class="link-light">
                  Faturayı Görüntüle
                  <i class="ri-arrow-right-s-line align-middle lh-1"></i>
                </b-link>
              </div>
            </b-card-footer>
          </b-card>
          <b-card-header class="border-bottom-dashed p-4 bg-soft-secondary">
            <b-row class="g-3">
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Belge Türü</p>
                <h5 class="fs-15 mb-0">{{ docBody.typeText }}</h5>
              </b-col>
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Belge Num.</p>
                <h5 class="fs-15 mb-0">#{{ docBody.docnum }}</h5>
                <span class="text-muted fs-12" type="button" v-b-tooltip.hover title="Harici Belge Num.">123456</span>
              </b-col>
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Tarih</p>
                <h5 class="fs-15 mb-0">{{ docBody.docDate }}</h5>
              </b-col>
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Belge Durumu</p>
                <span class="badge fs-12" :class="[docBody.statusColor]">{{ docBody.statusText }}</span>
              </b-col>
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Tutar</p>
                <h5 class="fs-15 mb-0"> {{ $filters.currency( parseFloat(docTotal) ) }} </h5>
              </b-col>
              <b-col lg="2" cols="4">
                <p class="text-muted mb-2 text-uppercase fw-semibold fs-13">Belge Sorumlusu</p>
                <h5 class="fs-15 mb-0">
                  <img
                      class="rounded-circle avatar-xs mr-1"
                      src="@/assets/images/users/avatar-1.jpg"
                      alt="Header Avatar"
                  />
                  <span class="ms-2">{{ docBody?.resp }}</span>
                </h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-header class="border-bottom-dashed p-4 bg-light">
            <div class="d-flex flex-column-reverse flex-sm-row">
              <div class="flex-grow-1">
                <div class="mt-1">
                  <b-row class="g-3">
                    <b-col sm="12">
                      <p class="fw-medium mb-2">{{ docBody.name1 }}</p>
                      <p class="text-muted mb-0">Telefon: {{ docBody?.telnum }}</p>
                      <p class="text-muted mb-0">E-Posta: {{ docBody?.texnum }}</p>
                      <p class="text-muted mb-0">VD/VN: {{ docBody?.taxnum }} / {{ docBody?.taxdept }}</p>
                    </b-col>
                    <b-col sm="12" v-if="docBody.grcadrnum == docBody.ircadrnum">
                      <h6 class="text-uppercase fw-semibold mb-3 fs-13">Fatura & Sevkiyat Adresi</h6>
                      <p class="text-muted mb-1">{{ docBody.iaddress }}</p>
                      <p class="text-muted mb-1">{{ docBody.itown + ' / ' + docBody.icity }}</p>
                    </b-col>
                    <b-col sm="6" v-if="docBody.grcadrnum != docBody.ircadrnum">
                      <h6 class="text-uppercase fw-semibold mb-3 fs-13">Fatura Adresi</h6>
                      <p class="text-muted mb-1">{{ docBody.iaddress }}</p>
                      <p class="text-muted mb-1">{{ docBody.itown + ' / ' + docBody.icity }}</p>
                    </b-col>
                    <b-col sm="6" v-if="docBody.grcadrnum != docBody.ircadrnum">
                      <h6 class="text-uppercase fw-semibold mb-3 fs-13">Sevkiyat Adresi</h6>
                      <p class="text-muted mb-1">{{ docBody.daddress }}</p>
                      <p class="text-muted mb-1">{{ docBody.dtown + ' / ' + docBody.dcity }}</p>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="flex-shrink-0 mt-0 mb-3 mb-sm-0">
                <img src="@/assets/images/logo-dark.png" class="card-logo card-logo-dark"
                     alt="logo dark" height="32">
                <img src="@/assets/images/logo-light.png" class="card-logo card-logo-light"
                     alt="logo light" height="32">
                <h6 class="m-0"><span class="fw-normal">{{ companyData["01"].name }}</span></h6>
                <p class="text-muted mb-0">{{ companyData["01"].address }}</p>
                <p class="text-muted mb-1">{{ companyData["01"].town }}/{{ companyData["01"].city }}</p>
                <h6 class="m-0"><span class="text-muted fw-normal">E-Posta: </span>
                  <a :href="'mailto:'+companyData['01'].email">{{ companyData["01"].email }}</a>
                </h6>
                <h6 class="m-0"><span class="text-muted fw-normal">Website:</span>
                  <b-link :href="'https://'+ companyData['01'].website +'/'" class="link-primary" target="_blank">
                    {{ companyData["01"].website }}
                  </b-link>
                </h6>
                <h6 class="mb-1"><span class="text-muted fw-normal">Müşteri Hizmetleri: </span>
                  <b-link :href="'tel:+9'+companyData['01'].phone.replaceAll(' ','')">{{ companyData["01"].phone }}</b-link></h6>
                <h6><span class="text-muted fw-normal">VD / VN: </span>
                  <span>{{ companyData["01"].taxdept }} / {{ companyData["01"].taxnum }}</span>
                </h6>
              </div>
            </div>
          </b-card-header>
          <b-card-body class="p-4">
            <div class="table-responsive">
              <table class="table align-middle mb-0 text-center table-striped">
                <thead class="table-light align-middle">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" colspan="2" style="width: 45%">Ürün</th>
                  <th scope="col">Miktar</th>
<!--                  <th style="width: 10%;" scope="col">Fiyat</th>-->
<!--                  <th scope="col">İskonto</th>-->
<!--                  <th style="width: 10%;" scope="col">Net Fiyat</th>-->
                  <th colspan="3" scope="col">Fiyat</th>
                  <th scope="col" style="width: 15%">Alt Toplam</th>
                  <th scope="col" style="width: 10%">KDV</th>
                  <th scope="col" style="width: 15%">Genel Toplam</th>
                  <th scope="col">Tah. Teslimat Tarihi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) of product_detail" :key="index" :class="{'bg-soft-success': parseInt(item.acikmiktar) == 0, 'bg-soft-warning' : parseInt(item.miktar) > parseInt(item.acikmiktar) && parseInt(item.acikmiktar) != 0}">
                  <th scope="row"> {{ index+1 }} </th>
                  <td width="10%" style="min-width: 64px;">
                    <b-img :src="item.img" alt="" class="img-thumbnail"></b-img>
                  </td>
                  <td class="text-start">
                    <span class="fw-medium">{{ item.stext }}</span>
                    <p class="text-muted mb-0">
                      {{item.material}} - {{ item.category }} - {{item.urunkodu}}
                    </p>
                  </td>
                  <td class="text-nowrap">
                    {{ parseInt(item.miktar) }}
                    <br />
                    <span class="text-muted fs-12" type="button" v-b-tooltip.hover title="Aktif Miktar" >
                      ({{ parseInt(item.acikmiktar) }})
                    </span>
                  </td>
                  <td colspan="3" class="text-nowrap">
                    <span class="text-decoration-line-through text-muted">{{ $filters.currency( parseFloat(item.nsprice) ) }}</span><br />
                    {{ parseInt(item.discrate1) != 0 ? ' '+item.discrate1 + '%' : '' }}
                    {{ parseInt(item.discrate2) != 0 ? ' + '+item.discrate2 + '%' : '' }}
                    {{ parseInt(item.discrate3) != 0 ? ' + '+item.discrate3 + '%' : '' }}
                    {{ parseInt(item.discrate4) != 0 ? ' + '+item.discrate4 + '%' : '' }}<br />
                    {{ $filters.currency( item.nprice ) }}
                  </td>
<!--                  <td class="text-nowrap">{{ $filters.currency( parseFloat(item.nsprice) ) }}</td>-->
<!--                  <td>-->
<!--                    {{ parseInt(item.discrate1) != 0 ? ' '+item.discrate1 : '' }}-->
<!--                    {{ parseInt(item.discrate2) != 0 ? ' '+item.discrate2 : '' }}-->
<!--                    {{ parseInt(item.discrate3) != 0 ? ' '+item.discrate3 : '' }}-->
<!--                    {{ parseInt(item.discrate4) != 0 ? ' '+item.discrate4 : '' }}-->
<!--                  </td>-->
<!--                  <td class="text-nowrap">{{ $filters.currency( item.nprice ) }}</td>-->
                  <td class="text-nowrap">{{ $filters.currency( item.subTotal ) }}</td>
                  <td class="text-nowrap">{{ $filters.currency( item.countTax ) }}</td>
                  <td class="text-nowrap">{{ $filters.currency( item.nprice * parseFloat(item.miktar) + item.countTax ) }}</td>
                  <td>{{ item.wsTahTeslim }}</td>
                </tr>
                </tbody>
                <tfoot class="table-light">
                <tr>
                  <td colspan="10" class="text-end">Ara Toplam</td>
                  <td class="text-nowrap">{{ $filters.currency( parseFloat(subTotal) ) }}</td>
                </tr>
                <tr>
                  <td colspan="10" class="text-end">Top. İskonto</td>
                  <td class="text-nowrap">{{ $filters.currency( parseFloat(totalDiscount) ) }}</td>
                </tr>
                <tr>
                  <td colspan="11" class="text-end">
                    <table class="float-end mb-0 table" style="width:20%">
                      <tr>
                        <td :rowspan="Object.keys(totalTax).length + 1">KDV</td>
                      </tr>
                      <tr v-for="(value, tax) in totalTax" :key="tax">
                        <td>%{{ tax }}</td>
                        <td>{{ $filters.currency( parseFloat(value) ) }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colspan="10" class="text-end">Genel Toplam</td>
                  <td class="text-nowrap">{{ $filters.currency( parseFloat(docTotal) ) }}</td>
                </tr>
                </tfoot>
              </table>
              <!-- end table -->
            </div>
            <!-- end table responsive -->
            <div class="mt-3">
              <h6 class="text-muted text-uppercase fw-semibold fs-13 mb-3">Ödeme Detayları:</h6>
              <p class="text-muted mb-1">Ödeme Yöntemi: <span class="fw-medium">{{
                  docBody.paymtype
                }}</span></p>
              <p class="text-muted mb-1">Ödeme Tipi: <span class="fw-medium">
                {{ (parseInt(docBody.payday) > 1) ? docBody.payday + ' Gün Vadeli' : 'Nakit'  }}</span></p>
              <p class="text-muted">Toplam Tutar: <span class="fw-medium"></span>{{ $filters.currency( parseFloat(docTotal) ) }}</p>
            </div>
            <div class="mt-4" v-if="docBody.ltext != ''">
              <b-alert variant="info" show>
                <p class="mb-0"><span class="fw-semibold">Not:</span>
                  <span id="note">{{ docBody.ltext }}</span>
                </p>
              </b-alert>
            </div>
            <div class="hstack gap-2 justify-content-end d-print-none mt-4">
              <b-link href="javascript:window.print()" class="btn btn-success"><i
                  class="ri-printer-line align-bottom me-1"></i> Print</b-link>
              <b-link href="javascript:void(0);" class="btn btn-primary"><i
                  class="ri-download-2-line align-bottom me-1"></i>
                Download</b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>